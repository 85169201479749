import axios from '@/utils/request';
import URL from '@/shared/constant/url';
import { getUrl } from '../utils';
import { DataListResponse, SuccessResponse } from '@/types/response';
import { JavaEntity, EntityStruct, CreateModel, ImportModel, CreateCtrlDim } from '@/components/data-model/erd/types';
import { apiProxy } from '../proxy/proxy';
import SERVER_TYPES from '@/shared/servertype';
const { schema } = URL;

export const getModelList = (payload?: object): Promise<DataListResponse> =>
  axios.get(getUrl(schema.GET_MODEL_LIST), {
    params: payload,
  });

export const getModelListAll = (payload?: object): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(schema.GET_MODEL_LIST_ALL), {
    params: payload,
  });

export const getServiceModelList = (payload?: object): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(schema.GET_SERVICE_MODEL_LIST), {
    params: payload,
  });
export const getModelDetail = (id: number) => axios.get(`${getUrl(schema.GET_MODEL_DETAIL)}/${id}`);

export const createModel = (payload: CreateModel): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(schema.CREATE_MODEL), payload);

export const getCtrlDim = (id: number) => axios.get(`${getUrl(schema.GET_CTRL_DIM)}/${id}`);

export const getCtrlDimByFieldId = (id: number) => axios.get(`${getUrl(schema.GET_CTRL_DIM_BYFIELD)}/${id}`);
export const getCtrlDimAll = () => axios.get(`${getUrl(schema.GET_CTRL_DIM_ALL)}`);

export const createCtrlDim = (payload: CreateCtrlDim): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(schema.CREATE_CTRL_DIM), payload);

export const updateCtrlDim = (payload: object, id: number): Promise<SuccessResponse<any>> =>
  axios.post(`${getUrl(schema.UPDATE_CTRL_DIM)}/${id}`, payload);

export const deleteCtrlDim = (id: number): Promise<SuccessResponse<any>> =>
  axios.post(`${getUrl(schema.DELETE_CTRL_DIM)}/${id}`);

export const updateModel = (payload: object, id: number): Promise<SuccessResponse<any>> =>
  axios.post(`${getUrl(schema.UPDATE_MODEL)}/${id}`, payload);

export const deleteModel = (payload: object): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(schema.DELETE_MODEL), payload);

export const createRelation = (payload: object): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(schema.CREATE_RELATION), payload);

export const updateRelation = (relationId: string, payload: object): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(schema.UPDATE_RELATION, relationId), payload);

export const removeRelation = (payload: object): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(schema.REMOVE_RELATION), payload);

export const updateConfig = (payload: object): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(schema.UPDATE_CONFIG), payload);

export const updateFields = (modelId: string, payload: object): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(schema.UPDATE_FIELDS, modelId), payload);

export const parseJavaEntity = (data: JavaEntity): Promise<SuccessResponse<{ structs: EntityStruct[] }>> =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, schema.PARSE_ENTITY, {
    method: 'POST',
    data,
  });

export const importModel = (payload: ImportModel): Promise<SuccessResponse<{ id: number }>> =>
  axios.post(getUrl(schema.IMPORT_MODEL), payload);

// 获取标准字段列表
export const getStandardFields = (payload?: object): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(schema.GET_STANDARD_FIELDS), {
    params: payload,
  });

// 获取条件索引字段列表
export const getIndexFields = (modelId?: string): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(schema.GET_INDEX_FIELDS, modelId));

// 更新对象模型索引
export const updateModelIndex = (modelId: string, payload: object): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(schema.UPDATE_INDEX, modelId), payload);

export const getCustomDataType = () =>
  apiProxy(SERVER_TYPES.SERVICE_GENERATOR, schema.GET_CUSTOM_TYPE, {
    method: 'get',
  });
