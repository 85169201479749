
import { ref, reactive, computed, getCurrentInstance, onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { addDataType, getDataTypeDetail, updateDataType } from '@/api/settings/data-types';
import { getShowBool } from '@/utils/permission-show-module';
import { getCustomDataType } from '@/api/schema/model';

export default {
  name: 'DataTypeEdit',
  setup() {
    // 路由器
    const router = useRouter();

    // 路由信息
    const route = useRoute();

    // 表单数据
    const typeForm = reactive({
      name: '',
      type: '',
      dbType: '',
      length: 1,
      description: '',
      precision: 0,
    });

    // 是否为新建数据类型
    const isCreate = computed(() => !route.params.id);

    const submitting = ref(false);

    // 初始化表单数据
    const initTypeForm = async () => {
      if (!isCreate.value) {
        const {
          data: { name, description, length, type, precision, dbType },
        } = await getDataTypeDetail(route.params.id as string);
        typeForm.name = name;
        typeForm.description = description;
        typeForm.length = length;
        typeForm.type = type;
        typeForm.precision = precision;
        typeForm.dbType = dbType;
      }
    };

    initTypeForm();

    // 获取组件实例
    const instance = getCurrentInstance();

    // 表单引用
    const formRef: any = ref(null);

    // 表单校验规则
    const formRules = {
      name: [
        { required: true, message: '请输入类型名称', trigger: 'blur' },
        { max: 64, message: '长度不超过 64 个字符', trigger: 'blur' },
      ],
      dbType: [{ required: true, message: '请输入数据类型', trigger: 'blur' }],
      length: [{ required: true, message: '请输入长度', trigger: 'blur' }],
      precision: [{ required: true, message: '请输入精度', trigger: 'blur' }],
      description: [
        { required: true, message: '请输入类型描述', trigger: 'blur' },
        { max: 255, message: '长度不超过 255 个字符', trigger: 'blur' },
      ],
    };

    // 获取数据库类型
    const dbTypes = ref([]);
    const getAllDbTypes = async () => {
      try {
        const { data, code } = await getCustomDataType();
        if (code === 0) {
          dbTypes.value = data;
        }
      } catch (e) {
        console.log(e);
      }
    };
    getAllDbTypes();

    const currentTypeObj = computed(() => {
      if (!typeForm.dbType) {
        return {};
      }
      return dbTypes.value.find((item: any) => item.dbType === typeForm.dbType);
    });

    // 提交表单
    const onSubmit = () => {
      formRef.value.validate(async (valid: boolean) => {
        if (valid) {
          submitting.value = true;
          if (isCreate.value) {
            typeForm.type = (currentTypeObj.value as any).javaType;
            const { code }: any = await addDataType(typeForm);
            if (code === 0) {
              (instance as any).proxy.$message({
                message: '新建成功',
                type: 'success',
              });
              submitting.value = false;
              router.back();
            }
          } else {
            const { code }: any = await updateDataType(route.params.id as any, typeForm);
            if (code === 0) {
              (instance as any).proxy.$message({
                message: '更新成功',
                type: 'success',
              });
              submitting.value = false;
              router.back();
            }
          }
        }
      });
    };

    const onCancel = () => {
      router.back();
    };

    onBeforeMount(() => {
      getAllDbTypes();
    });

    return {
      typeForm,
      formRef,
      formRules,
      onSubmit,
      onCancel,
      getShowBool,
      submitting,
      dbTypes,
      currentTypeObj,
    };
  },
};
